const translations = {
  es: {
    version: 'Versión',
    idioma: 'IDIOMA (ES)',
    bienvenido: 'Bienvenido(a)',
    colegioAleman: 'Colegio Alemán',
    cerrarSesion: 'CERRAR SESIÓN',
    iniciarSesion: 'Iniciar sesión',
    restablecimientoContrasenia: 'Restablecimiento de contraseña',
    restablecimientoContraseniaMensaje:
      'Para recuperar tu contraseña, por favor ingresa tu correo electrónico, las instrucciones te serán enviadas.',
    restablecimientoContraseniaMensajeEnviado:
      'En este momento estamos enviando instrucciones para recuperar la contraseña al correo: ',
    correoElectronico: 'Correo electrónico',
    contrasenia: 'Contraseña',
    menuAyuda: 'Ayuda',
    mensajeAyuda:
      'Para tener una mejor experiencia le recomendamos utilizar su dispositivo de manera <strong>horizontal</strong>',
    modulo: {
      usuarios: 'Usuarios',
      cursos: 'Cursos',
      calendario: 'Calendario',
      reportes: 'Reportes',
      permisos: 'Revisión de permisos',
      kardex: 'Kardex',
      notificaciones: 'Notificaciones',
      libroDeCurso: 'Libro de Curso',
      circulares: 'Circulares',
      tickets: 'Tickets',
      certificados: 'Certificados',
    },
    solicitudes: 'Solicitudes',
    recibidas: 'Solicitudes Recibidas',
    aprobadas: 'Solicitudes Aprobadas',
    rechazadas: 'Solicitudes Rechazadas',
    observadas: 'Solicitudes Observadas',
    eliminadas: 'Solicitudes Eliminadas',
    agregarPermiso: 'Agregar permiso',
    refrescar: 'Refrescar',
    sinRegistroPermisos: 'No hay datos de permisos',
    remitente: 'Remitente',
    curso: 'Curso',
    fechaRecepcion: 'Fecha de recepción',
    creadoPor: 'Creado por',
    motivo: 'Motivo',
    comentario: 'Comentario',
    fechaPermiso: 'Fecha de permiso',
    fechaDesdeMenorFechaHasta:
      'La fecha de inicio debe ser menor a la fecha de fin',
    fechaHastaMayorFechaDesde:
      'La fecha de fin debe ser mayor a la fecha de inicio',
    sinAdjuntos: 'Sin adjuntos',
    solicitudesPermisosAprobados: 'Solicitudes de permiso aprobadas',
    horaPermiso: 'Hora del permiso',
    solicitarInfo: 'Solicitar + Info',
    aprobar: 'Aprobar',
    rechazar: 'Rechazar',
    enviarCorreo: 'Enviar correo',
    enviar: 'Enviar',
    enviando: 'Enviando...',
    estudiante: 'Estudiante',
    categoria: 'Categoría',
    padre: 'Padre',
    estado: 'Estado',
    fechaSolicitud: 'Fecha de solicitud',
    altaMedica: 'Alta médica',
    solicitarMayorInformacion: 'Solicitar mayor información',
    solicitarMayorInformacionAltaMedica:
      'Solicitar mayor información para el Alta médica',
    motivoObservacion: 'Motivo de observación',
    permisoParcialNoAfectaAsistencia:
      'Este es un permiso parcial, no afectará a los registros de asistencia',
    permisoAprobadoKinder:
      'Este es un permiso aprobado por el profesor responsable de Kindergarten',
    tienePermiso: 'Tiene permiso',
    tienePermisoParcial: 'Tiene permiso parcial',
    form: {
      datosUsuario: 'Datos de usuario',
      datosPermiso: 'Datos de permiso',
      seleccionaEstudiante: '-Selecciona un estudiante-',
      descripcion: 'Descripción',
      agregarCircular: 'Agregar circular',
      crearCertificado: 'Crear Certificados',
      nroCircular: 'Nro. de circular',
      asunto: 'Asunto',
      contenido: 'Contenido',
      editarCircular: 'Editar circular',
      crearNuevaCircular: 'Crear nueva circular',
      editandoCircular:
        'Edición de circular en proceso, por favor espere un momento.',
      creandoCircular:
        'Creación de circular en proceso, por favor espere un momento.',
      vistaPrevia: 'Vista previa',
      modoEdicion: 'Modo edición',
      inserteInformacionES:
        'Inserte la información de la circular en ESPAÑOL, por favor.',
      inserteInformacionDE:
        'Inserte la información de la circular en ALEMÁN, por favor.',
      escribaNumeroCircular: 'Escriba el número de circular',
      escribaAsuntoCircular: 'Escriba el asunto de la circular',
      escribaContenidoCircular: 'Escriba el contenido de la circular',
      seleccionaMotivo: 'A continuación, selecciona un motivo',
      fechaInicio: 'Fecha de inicio',
      fechaFin: 'Fecha fin',
      aceptarYGuardar: 'Aceptar y guardar',
      aceptar: 'Aceptar',
      adicionar: 'Adicionar',
      guardar: 'Guardar',
      cancelar: 'Cancelar',
      publicar: 'Publicar',
      tituloTarea: 'Título de la tarea',
      seleccioneFechaEntrega: 'Seleccione la fecha de entrega',
      adjuntos: 'Archivos adjuntos',
      arrastreYSuelteUnArchivo:
        'Arrastre y suelte un archivo aquí o haga click aquí',
      tamanioMaximo: 'Tamaño máximo',
      seleccioneCategoria: 'A continuación, seleccione la categoria',
      seleccioneEstudiante: 'A continuación, seleccione al estudiante',
      seleccione: '-Seleccione-',
      motivoSolicitudInformacion: 'Motivo de solicitud de información',
      descripcionSolicitudInformacion:
        'Descripción de solicitud de información',
      seleccioneMotivo: 'A continuación, seleccione un motivo',
      fecha: 'Fecha',
      escribaMasInformacionParaPadre:
        'Escriba mas información para el padre de familia',
      escribaMasInformacion: 'Escriba más información',
      seleccionaUnFamiliar:
        'Selecciona un familiar o llene los datos del nuevo familiar',
      escribaElTituloDelEvento: 'Escriba el título del evento',
      escribaLaDecripcionDelEvento: 'Escriba la descripción del evento',
      otroMotivoObservacion: 'Otro motivo',
      describeOtroMotivoObservacion: 'Describe el motivo',
      motivoTicket: 'Tipo de problema',
      escribaProblemaTicket: 'Puede dar mas detalles por favor',
      subirArchivos: 'Adjuntar archivos',
      dropzoneTextFiles:
        'Arrastre y suelte un archivo aquí o haga click. (Tamaño máximo: 10MB)',
      creandoTicket: 'Enviando ticket, espere por favor.',
      descripcionTicket: 'Descripción del problema',
      ubicacionTicket: 'Escriba la ubicación en la que se encuentra',
      archivoSeleccionado: 'Archivo seleccionado'
    },
    opcion: {
      MOTIVO_SALUD: 'SALUD',
      MOTIVO_VIAJE: 'VIAJES DE SALUD',
      MOTIVO_VIAJE_OTRO: 'VIAJES VARIOS',
      MOTIVO_OTRO: 'VARIOS',
      MOTIVO_TORNEO: 'TORNEOS DEPORTIVOS',
      MOTIVO_AUSENCIA: 'AUSENCIA NOTIFICADA',
      MOTIVO_CITA_MEDICA: 'CITA MÉDICA / EXÁMENES MÉDICOS',
      MOTIVO_TRAMITES: 'TRÁMITES',
      SALUD: 'Salud',
      VIAJE: 'Viajes de salud',
      VIAJE_OTRO: 'Viajes varios',
      TORNEO: 'Torneos deportivos',
      OTRO: 'Varios',
      AUSENCIA: 'Ausencia notificada',
      CITA_MEDICA: 'Cita Médica / Exámenes Médicos',
      TRAMITES: 'Trámites',
    },
    mensaje: {
      contraseniaIncorrecta: 'La contraseña es incorrecta',
      noExisteUsuario: 'No existe el usuario',
      correoInvalido: 'No es un correo electrónico válido',
      errorDesconocido: 'Error desconocido',
      correcto: '¡Correcto!',
      error: '¡Error!',
      advertencia: '¡Advertencia!',
      confirmar: 'Confirmar',
    },
    reportes: 'Reportes',
    reporte: {
      permisosPorEstudiante: 'Permisos por estudiante',
      asistenciasPorMateria: 'Asistencias por materia',
      libroClasesPorSesion: 'Libro de clases por sesión',
      libroClasesAcumulado: 'Libro de clases no llenado (acumulado)',
      trimestral: 'Asistencias y Anotaciones',
      datosGenerales: 'Datos generales',
      kardexDetalle: 'Detalle',
      asistencia: 'Asistencia',
      anotaciones: 'Anotaciones',
      tareas: 'Tareas',
      correoEstudiante: 'Correo del estudiante',
      curso: 'Curso',
      padre: 'Padre',
      correoPadre: 'Correo del padre',
      historial: 'Historial de curso',
      buscarEstudiante: 'Buscar estudiante',
      cargando: 'Cargando',
      sinOpciones: 'Sin opciones',
      verAsistencias: 'Ver Asistencias',
      verComoJefeCurso: 'Ver como Jefe de curso',
      estudiante: 'Estudiante',
      familiar1: 'Familiar N° 1',
      familiar2: 'Familiar N° 2',
      correo: 'Correo',
      celular: 'Contacto',
      direccion: 'Dirección',
      kinderAsistencia: 'Asistencia',
      permisos: 'Permisos',
      libroDeCursoNoLlenado: 'Libro de curso no llenado',
      kinderGraficoAreasDesarrollo: 'Gráfico áreas de desarrollo',
      kinderGraficoAreas: 'Gráfico de competencias',
      alergias: 'Alergias',
      accidentes: 'Accidentes',
    },
    date: {
      long: 'dddd, D [de] MMMM [de] YYYY',
    },
    calendario: {
      siguiente: 'Siguiente',
      anterior: 'Anterior',
      hoy: 'Hoy',
      hoyEs: 'Hoy es',
      mes: 'Mes',
      semana: 'Semana',
      dia: 'Día',
      agenda: 'Agenda',
      todoElDia: 'Todo el día',
      feriado: 'Feriado',
      eventoInterno: 'Evento interno',
      comienza: 'Comienza',
      termina: 'Termina',
      noHayEventosEnEsteRango: 'No hay eventos en este rango de fechas',
      evento: 'Evento',
      fecha: 'Fecha',
      lunes: 'Lunes',
      martes: 'Martes',
      miercoles: 'Miércoles',
      jueves: 'Jueves',
      viernes: 'Viernes',
      sabado: 'Sábado',
      domingo: 'Domingo',
      enero: 'Enero',
      febrero: 'Febrero',
      marzo: 'Marzo',
      abril: 'Abril',
      mayo: 'Mayo',
      junio: 'Junio',
      julio: 'Julio',
      agosto: 'Agosto',
      septiembre: 'Septiembre',
      octubre: 'Octubre',
      noviembre: 'Noviembre',
      diciembre: 'Diciembre',
    },
    tabla: {
      refrescar: 'Refrescar',
      filasPorPagina: 'Filas por página',
      desde: 'Desde',
      hasta: 'Hasta',
      sinRegistrosReporte: 'No hay datos del reporte',
      buscar: 'Buscar',
      buscarPor: 'Buscar por',
      de: 'de',
      masDe: 'más de',
      generarExcel: 'Descargar Excel',
      generarExcelPermisos: 'Descargar reporte diario',
      nro: 'Nro.',
      nombre: 'Nombre',
      primerApellido: 'Primer apellido',
      segundoApellido: 'Segundo apellido',
      nivel: 'Nivel',
      grado: 'Grado',
      curso: 'Curso',
      permisosSolicitados: 'Permisos solicitados',
      permisosAprobados: 'Permisos aprobados',
      permisosRechazados: 'Permisos rechazados',
      primaria: 'Primaria',
      secundaria: 'Secundaria',
      kinder: 'Kinder',
      asistencia: 'Asistencia',
      atrasos: 'Retrasos',
      faltasConLicencia: 'Faltas con licencia',
      faltasSinLicencia: 'Faltas sin licencia',
      minutosAcumulados: 'Minutos acumulados de retraso',
      anotaciones: 'Anotaciones',
      nombreProfesor: 'Nombre del Profesor',
      primerApellidoProfesor: 'Primer apellido del Profesor',
      segundoApellidoProfesor: 'Segundo apellido del Profesor',
      siglaProfesor: 'Sigla profesor',
      materia: 'Materia',
      fecha: 'Fecha',
      avance: 'Avance',
      salud: 'Salud',
      viaje: 'Viaje',
      otro: 'Otro',
      recibido: 'Recibido',
      aprobado: 'Aprobado',
      rechazado: 'Rechazado',
      observado: 'Observado',
      acciones: 'Acciones',
      fechaPublicacion: 'Fecha de publicación',
      fechaActualizacion: 'Ultima actualización',
      estado: 'Estado',
      nombreCompleto: 'Nombre completo o email',
      paralelo: 'Paralelo',
      turno: 'Turno',
      todos: 'Todos',
      asesor: 'Asesor',
      fechaInicio: 'Fecha de inicio',
      fechaFin: 'Fecha fin',
      gestion: 'Gestión',
      asistio: 'Asistió',
      faltaSinLicencia: 'Falta sin licencia',
      faltaConLicencia: 'Falta con licencia',
      atrasado: 'Atrasado',
      llenarLibroClase: 'Llenar el libro de clase ahora',
      tipoProblema: 'Tipo de problema',
      contenido: 'Contenido',
    },
    crearEstudiante: 'Crear estudiante',
    sinRegistroEstudiantes: 'No hay datos de estudiantes',
    sinRegistroCursos: 'No hay datos de cursos',
    crearCurso: 'Crear curso',
    crearMateria: 'Crear materia',
    leccionDelDia: 'Lección del día',
    anotacionesSinTarea: 'Anotación: Sin tarea',
    escribeDesarrolloClase: 'Escribe el desarrollo de la clase de hoy',
    enEstaClaseNoSeDejoTarea:
      'En esta clase no se dejó tarea para los estudiantes',
    seleccioneEstudiantesSinTarea:
      'A continuación, seleccione a los estudiantes que no entregaron su tarea.',
    asistenciaPresente: 'Presente',
    asistenciaRetraso: 'Retraso',
    asistenciaFaltaSinLicencia: 'Falta sin licencia',
    noPresentado: 'No presentado',
    aContinuacionSeleccione: 'A continuación seleccione:',
    entrada: 'Entrada',
    salida: 'Salida',
    seleccioneLaCantidadMinutosRetraso:
      'Seleccione la cantidad de minutos de retraso:',
    hora: 'Hora',
    horaMinuto: 'Hora-Minuto',
    totalMinutosEntrada: 'Total minutos de entrada:',
    totalMinutosSalida: 'Total minutos de salida:',
    horas: 'horas',
    minutos: 'minutos',
    kinder: {
      volverMenu: 'Volver',
      tema: 'Tema',
      circuloDeColores: 'Círculo de colores',
      circulo: 'Círculo',
      deColores: 'de colores',
      asistencia: 'Asistencia',
      fecha: 'Fecha',
      libroDeCurso: 'Libro de Curso',
      libroDeCursoNoCamel: 'Libro de curso',
      libroDeCursoNoCamelB: 'Libro de curso',
      anadirActividad: 'Añadir Actividad',
      sinActividades:
        'Actualmente no se tiene una actividad creada en este tema.',
      crearNuevoTema: 'Crear nuevo tema',
      crearModalNuevoTema: 'Crear tema',
      crearModalNuevoCirculo: 'Crear círculo de colores',
      tituloTema: 'Título del tema',
      tituloCirculo: 'Título del círculo de colores',
      introduceTituloTema: 'Introduce el título del tema',
      introduceTituloCirculo: 'Introduce el título del circulo de colores',
      temaFechaInicioFin: 'Fecha de inicio y de finalización',
      cancelar: 'Cancelar',
      guardar: 'Guardar',
      guardando: 'Guardando...',
      actividadesMananaTitle:
        'Actividades y competencias del círculo de la mañana:',
      actividadesOtrosTitle: 'Otras actividades y competencias:',
      eventosDelDia: 'Eventos del día',
      guardarLibroDeCurso: 'Guardar Libro de curso',
      selecciona: 'Selecciona',
      nombreActividad: 'Descripción de la actividad',
      actividades: 'Actividades',
      competencias: 'Competencias',
      colEstudiante: 'Estudiante',
      colAsistencia: 'Asistencia',
      colRetraso: 'Retraso',
      primariaSecundaria: 'Primaria-Secundaria',
      kinder: 'Kindergarten',
      competenciasKinder: 'COMPETENCIAS KINDERGARTEN',
      formularioRegistroPermiso: 'Formulario de registro de permiso',
      formularioRegistroAccidentes: 'Formulario de registro de accidentes',
      datosEstudiante: 'Datos del estudiante',
      datosPermiso: 'Datos del permiso',
      comentario: 'Comentario',
      menuDelLibroDeCurso: 'Menú del Libro de curso',
      registrarPermisos: 'Registrar permisos',
      accidentes: 'Accidentes',
      estudiantesPresentes: 'Estudiantes presentes',
      estudiantesFaltaSinLicencia: 'Estudiantes con Falta sin licencia',
      estudiantesFaltaConLicencia: 'Estudiantes con Falta con licencia',
      seleccioneEstadoAsistencia:
        'A continuación selecciona el estado de asistencia del estudiante',
      asistenciaRegistrada: 'Asistencia registrada',
      validacionNombre:
        'Todas las actividades deben tener un nombre, verifique e inténtelo nuevamente.',
      validacionMinimoCompetencias:
        'Aún no se alcanzó el número de competencias mínimo requerido (1). Por favor, complete los campos e inténtelo nuevamente.',
      validacionOtrasActividades:
        'El apartado "OTRAS ACTIVIDADES Y COMPETENCIAS" se encuentra vacío, inserte una actividad y al menos una competencia para continuar.',
      validacionActividadesManana:
        'El apartado "ACTIVIDADES Y COMPETENCIAS PARA EL CÍRCULO DE LA MAÑANA" se encuentra vacío, inserte una actividad y mínimamente una competencia para continuar.',
      contactoEmergencia: 'Contacto de emergencia',
      parentesco: 'Parentesco',
      contacto: 'Contacto',
      curso: 'Curso',
      area: 'Área',
      seleccionarAreaBuscar: 'Seleccione un área y presione buscar',
      gestion: 'Gestión',
      comoFueAtendido: 'Como fue atendido',
    },
    agregarAnotacion: 'Agregar anotación',
    anotacion: 'Anotación',
    descargarPDF: 'Descargar PDF',
    datosDelFamiliar: 'Datos del familiar Nº',
    fechaAltaMedicaSolicitada: 'Fecha de alta médica solicitada',
    solicitudAltaMedicaAprobada: 'Solicitud de alta médica aprobada',
    solicitudAltaMedicaRecibida: 'Solicitud de alta médica recibida',
    solicitudAltaMedicaObservada: 'Solicitud de alta médica observada',
    solicitudAltaMedicaRechazada: 'Solicitud de alta médica rechazada',
    formularioSolicitudPermiso: 'Formulario de solicitud de permiso',
    informacionEnviadaCorrectamente: 'Información enviada correctamente',
    edicionPermisoExitoso: 'Edición del permiso exitoso',
    creacionPermisoExitoso: 'Creación del permiso exitoso',
    accidenteRegistrado: 'Accidente registrado correctamente',
    solicitudPermiso: 'Solicitud de permiso',
    confirm: {
      deseaAprobarPermiso: '¿Desea aprobar la solicitud de permiso?',
      deseaRechazarPermiso: '¿Desea rechazar la solicitud de permiso?',
      deseaSolicitarMasInformacion: '¿Desea solicitar más información?',
      deseaEliminarPermiso: '¿Desea eliminar la solicitud de permiso?',
      deseaAprobarAltaMedica: '¿Desea aprobar la solicitud de Alta médica?',
      deseaRechazarAltaMedica: '¿Desea rechazar la solicitud de Alta médica?',
      deseaContinuarYEliminarTarea:
        'Se encontró una tarea y será eliminada. ¿Desea continuar?',
    },
    observacion: 'Observación',
    notificaciones: {
      enviadas: 'Enviadas',
    },
    notificacion: 'Notificación',
    enviarNotificacion: 'Enviar notificación',
    help: {
      kinderFAQ: 'PREGUNTAS FRECUENTES - KINDERGARTEN',
      q1: '¿Cómo ingreso a la plataforma?',
      a1: 'A través de cualquier dispositivo con conexión a internet (Computadora o tableta),  puede ingresar al enlace &nbsp; <a href="https://ds-lapaz.io" target="_blank">https://ds-lapaz.io</a> &nbsp; con su navegador de preferencia.',
      q2: '¿Cómo puedo cambiar el idioma en la aplicación?',
      a2: 'El idioma predeterminado de la plataforma es español; sin embargo, el usuario podrá alternar entre español y alemán pulsando el botón IDIOMA (ES), situado en la cinta de opciones superior, el texto cambiará a IDIOM (DE) así como todo el texto de la plataforma.',
      q3: 'Ya ingresé al libro de clases, pero el botón de “Añadir actividad” se encuentra bloqueado. ¿A qué se debe?',
      a3: 'La plataforma está diseñada para que se siga un procedimiento específico, por lo que, para añadir actividades es necesario crear un tema primero en una determinada fecha, una vez que se realice esta acción, el botón cambia de color y se habilita. (La creación del Círculo de colores es voluntaria)',
      q4: '¿Puedo hacer la planificación del libro de clases para varias semanas en adelante?',
      a4: 'Si, la plataforma permite hacer planificaciones a futuro, creando los temas, círculos de colores, actividades y competencias en fechas posteriores a la actual.',
      q5: '¿Puedo designar diferentes rangos de fecha para el tema y el círculo de colores?',
      a5: 'Si, estos son campos independientes, por lo que cada uno puede tener una duración diferente.',
      q6: '¿Debo ingresar todos los días a guardar el registro de asistencia?',
      a6: 'Lo recomendable es registrar la asistencia diaria para corroborar que todos los estudiantes están en clase; de todas maneras, si no se registran cambios en la fecha actual, la plataforma por defecto registrara que todos los estudiantes estuvieron presentes.',
      q7: '¿Qué reportes puedo visualizar como profesor?',
      a7: 'Asistencias: En el que podrá ver el registro de asistencia de cada estudiante inscritos en su curso dentro de un determinado rango de tiempo, la cantidad de asistencias acumuladas, faltas sin licencia, faltas con licencia, retrasos y sus minutos acumulados.<br/>Competencias: En el que podrá visualizar de forma gráfica como fue el desempeño en clases en función a las áreas de desarrollo y competencias designadas para cada actividad. Esto permitirá estar al tanto de las áreas y competencias más y menos desarrolladas.',
      q8: '¿Cómo ingresamos ambos profesores a la información de un mismo curso en la plataforma?',
      a8: 'Cada curso está diseñado para tener dos profesores responsables, por lo que cada uno tiene un acceso individual al Libro de curso, Reportes y Kardex con sus propias credenciales (Es decir, correo electrónico y contraseña).',
      q9: '¿Dónde puedo ver el listado de asistencia para hacer el registro?',
      a9: 'En el primer apartado denominado “Libro de curso”, es el tercer botón a la derecha del calendario semanal.',
      q10: 'Si como profesor me llegó un correo de los padres indicando que el estudiante faltará, ¿puedo registrar una falta con licencia en la asistencia del día de hoy?',
      a10: 'El profesor no puede cambiar el estado de un estudiante a “falta con licencia”. El procedimiento formal de gestión de licencias, parciales y totales, es que el papá envía una solicitud a través de la aplicación y es el monitor del colegio quien se encarga de aprobarlos, rechazarlos u observarlos según ciertos criterios. Lo ideal es que se exhorte a los padres de familia a hacer uso de la aplicación para solicitar el permiso.',
      q11: '¿Qué pasa si ya le registré a un niño o niña una falta sin licencia y se les aprobó el permiso a los padres días después?. ¿Se me informará para cambiar el estado de asistencia?',
      a11: 'Una vez que la solicitud de permiso sea aprobada por el colegio, la información se consolidará por sobre las faltas sin licencia registradas en tales días. Es entonces que el estado del estudiante cambiará automáticamente a “Tiene licencia” y esto se reflejará en todos los reportes oficiales.',
      q12: '¿Cómo puedo ver los reportes de mi curso?',
      a12: 'Primeramente, se debe hacer clic en el botón “Reportes”. En la parte central superior se verá el texto “Primaria – Secundaria”, esta es una lista de selección, si pulsamos sobre la misma tendremos una segunda opción denominada “Kínder”. Elegimos “Kínder” y los reportes en pantalla que se mostrarán, serán los del nivel respectivo.',
      q13: 'En el Gráfico de competencias me indica que “No hay datos”. ¿Cómo soluciono esto?',
      a13: 'Se debe proceder a activar el filtro, primer botón dentro de la barra de opciones con tres líneas horizontales. Se desplegará una segunda línea con dos campos: Curso y Área.<br/><br/>Pulsamos sobre los espacios respectivos y primero seleccionamos nuestro curso, posteriormente   en   el   campo   “área”,   elegimos   el   área   de   desarrollo   cuyas competencias queramos reflejar en función a la frecuencia de aplicación y el gráfico aparecerá en pantalla.',
    },
    categoriaAnotacion: {
      otro: 'Otros',
      aplicacion: 'Aplicación',
      sinTarea: 'Sin Tarea',
      conducta: 'Conducta',
      orden: 'Orden',
      atencion: 'Atención',
    },
    anotacionesGuardadasConExito: 'Anotaciones guardadas con éxito',
    asistenciasGuardadasConExito: 'Lista de asistencia registrado con éxito',
    tareasGuardadasConExito: 'Guardando datos de la tarea, espere por favor',
    // #region TICKETS
    crearTicket: 'Crear ticket',
    infoCrearTicket:
      'Si tiene algún problema, por favor envíe un ticket y le responderemos a la brevedad.',
    enviarTicket: 'Enviar ticket',
    ubicacion: 'Ubicación',
    sinDatosTickets: 'No hay datos de tickets',
    // #endregion
    // #region CERTIFICADOS
    certificado: 'Certificado',
    certificadoNuevo: 'Crear certificado nuevo',
    editarCertificado: 'Editar certificado',
    certificadoExterno: 'Crear certificado externo (PDF)',
    subirCertificado: 'Subir certificado existente',
    certificadoProfesor: 'Certificado profesores',
    tipoCertificado: 'Tipo de certificado',
    sinRegistrosCertificados: 'No existe certificados',
    seleccioneTipoCertificado: 'Certificado...',
    datosDestinatario: 'Datos del destinatario',
    textoAyudaCertificadoExistenteDestinatario:
      'Busque y seleccione a la persona a quien se enviará el certificado',
    esProfesor: 'Es un profesor',
    buscarProfesor: 'Buscar profesor',
    buscarEstudiante: 'Buscar estudiante',
    seleccionarCertificado: 'Seleccione el tipo de certificado',
    cargarListaAyuda:
      'Carga una lista de destinatarios para generar el certificado',
    cargarLista: 'Cargar lista de destinatarios',
    completarTextoCertificado:
      'Complete el texto que sigue a la frase genérica:',
    textAyudaCertificado:
      'No es necesario volver a escribir la frase (ya incluida en el certificado).',
    descargarCertificadoEjemplo:
      'Opcional: Descargar la plantilla para cargar correctamente los datos, si es necesario',
    regresar: 'Atrás para editar',
    revisionConfirmacionCertificados: 'Revisión y confirmación de certificados',
    revisionConfirmacionCertificadosTitulo:
      'Revisa la lista de certificados y realiza ajustes si es necesario volviendo atrás para editar',
    numeroDeCertificados: 'Número de certificados: ',
    sinCertificadosRevision: 'No se encontró ningún certificado para generar',
    generarCertificados: 'Generar certificados',
    creandoCertificados: 'Certificados se han enviado exitosamente',
    agregarAdjunto: 'Añadir adjunto',
    textoAyudaAdjunto:
      'Suba el archivo desde su dispositivo para adjuntar el certificado',
    previsualizarCertificado: 'Pre-visualizar certificado',
    fechaCertificado: 'Seleccionar la fecha del certificado',
    debeAdjuntarArchivoOIngresarURL:
      'Debe adjuntar un archivo o ingresar una URL',
    debeSeleccionarUsuario: 'Debe seleccionar un usuario',
    datosEspaniolCertificado: 'Inserta los datos comunes para generar los certificados en ESPAÑOL, por favor.',
    datosAlemanCertificado: 'Inserta los datos comunes para generar los certificados en ALEMÁN, por favor.',
    temaCertificado: 'Tema del certificado',
    tematicasFormacion: 'Temáticas de la formación',
    capacitadores: 'Capacitadores',
    participantes: 'Participantes'
    // #endregion
  },
  de: {
    version: 'App version',
    idioma: 'IDIOM (DE)',
    bienvenido: 'Willkommen',
    colegioAleman: 'Deutsche Schule La Paz',
    cerrarSesion: 'ABMELDEN',
    iniciarSesion: 'Einloggen',
    restablecimientoContrasenia: 'Passwort zurücksetzen',
    restablecimientoContraseniaMensaje:
      'Um Ihr Passwort wiederherzustellen, geben Sie bitte unten Ihre E-Mail-Adresse ein',
    restablecimientoContraseniaMensajeEnviado:
      'In diesem Moment senden wir Anweisungen an die Mail um Ihr Passwort abzurufen',
    correoElectronico: 'Email',
    contrasenia: 'Passwort',
    menuAyuda: 'Hilfe',
    mensajeAyuda:
      'Für ein optimales Erlebnis empfehlen wir, Ihr Gerät <strong>horizontal<strong> zu verwenden.',
    modulo: {
      usuarios: 'Benutzer',
      cursos: 'Kurse',
      calendario: 'Kalender',
      reportes: 'Berichte',
      permisos: 'Genehmigungsprüfung',
      kardex: 'Kardex',
      notificaciones: 'Mitteilungen',
      libroDeCurso: 'Gruppenbuch',
      circulares: 'Rundschreiben',
      tickets: 'Tickets',
      certificados: 'Zertifikate',
    },
    solicitudes: 'Anfragen',
    recibidas: 'Eingegangene Anfragen',
    aprobadas: 'Genehmigungen',
    rechazadas: 'Abgelehnte Anträge',
    observadas: 'Anmerkungen',
    eliminadas: 'Papierkorb',
    agregarPermiso: 'Berechtigung hinzufügen',
    refrescar: 'Neu laden',
    sinRegistroPermisos: 'Keine Berechtigungsdaten',
    remitente: 'Absender',
    curso: 'Kurs',
    fechaRecepcion: 'Empfangsdatum',
    creadoPor: 'Erstellt von',
    motivo: 'Grund',
    comentario: 'Kommentar',
    fechaPermiso: 'Abreisedatum',
    fechaDesdeMenorFechaHasta:
      'Das Anfangsdatum muss kleiner als das Enddatum sein.',
    fechaHastaMayorFechaDesde:
      'Das Enddatum muss größer als das Anfangsdatum sein.',
    sinAdjuntos: 'Ohne Anhänge.',
    solicitudesPermisosAprobados: 'Genehmigte Genehmigungsanträge.',
    horaPermiso: 'Uhrzeit der Entlassung',
    solicitarInfo: 'Frage nach mehr Informationen',
    aprobar: 'Passieren',
    rechazar: 'Ablehnen',
    enviarCorreo: 'Post senden',
    enviar: 'Senden',
    enviando: 'Sendend...',
    estudiante: 'Student',
    categoria: 'Kategorie',
    padre: 'Vater',
    estado: 'Status',
    fechaSolicitud: 'Antragsdatum',
    altaMedica: 'Medizinische Entlassung',
    solicitarMayorInformacion: 'Weitere Informationen anfordern',
    solicitarMayorInformacionAltaMedica: 'Weitere Informationen anfordern',
    motivoObservacion: 'Grund für die Anmerkung',
    permisoParcialNoAfectaAsistencia:
      'Es handelt sich um eine stundenweise Genehmigung, die sich nicht auf die Anwesenheitsliste auswirkt.',
    permisoAprobadoKinder:
      'Dies ist eine vom zuständigen Kirdengartenlehrer genehmigte Erlaubnis',
    tienePermiso: 'Genehmigten Abwesenheiten',
    tienePermisoParcial: 'Teilgenehmigung',
    form: {
      datosUsuario: 'Benutzerdaten',
      datosPermiso: 'Berechtigungsdaten',
      seleccionaEstudiante: '-Wählen Sie einen Schüler aus-',
      descripcion: 'Beschreibung',
      agregarCircular: 'Rundschreiben hinzufügen',
      crearCertificado: 'Zertifikate erstellen',
      nroCircular: 'Rundschreibensnummer',
      asunto: 'Betreff',
      contenido: 'Inhalt',
      editarCircular: 'Rundschreiben bearbeiten',
      crearNuevaCircular: 'Neues Rundschreiben erstellen',
      editandoCircular:
        'Rundschreibenbearbeitung, bitte warten Sie einen Moment.',
      creandoCircular:
        'Rundschreiben wird erstellt, bitte warten Sie einen Moment.',
      vistaPrevia: 'Vorschau',
      modoEdicion: 'Bearbeitungsmodus',
      inserteInformacionES:
        'Bitte fügen Sie die Informationen des Rundschreibens auf SPANISCH ein.',
      inserteInformacionDE:
        'Bitte fügen Sie die Informationen des Rundschreibens auf DEUTSCH ein.',
      escribaNumeroCircular: 'eingeben Sie die Rundschreibennummer',
      escribaAsuntoCircular: 'eingeben Sie den Betreff des Rundschreibens ',
      escribaContenidoCircular: 'eingeben Sie den Inhalt des Rundschreibens ',
      seleccionaMotivo: 'Bitte wählen Sie unten einen Grund aus',
      fechaInicio: 'Startdatum',
      fechaFin: 'Endtermin',
      aceptarYGuardar: 'Akzeptieren und speichern',
      aceptar: 'kzeptieren',
      adicionar: 'hinzufügen',
      guardar: 'Speichern',
      guardando: 'Speichern...',
      cancelar: 'Abbrechen',
      publicar: 'Veröffentlichen',
      tituloTarea: 'Aufgabentitel',
      seleccioneFechaEntrega: 'Wählen Sie das Lieferdatum aus',
      adjuntos: 'Anhänge',
      arrastreYSuelteUnArchivo:
        'Ziehen Sie eine Datei per Drag & Drop hierher oder klicken Sie hier',
      tamanioMaximo: 'Maximale Dateigröße',
      seleccioneCategoria: 'Wählen Sie dann die Kategorie aus',
      seleccioneEstudiante: 'Wählen Sie dann den Schüler aus',
      seleccione: '-Wählen Sie-',
      motivoSolicitudInformacion: 'Grund der Nachfrage',
      descripcionSolicitudInformacion: 'Beschreibung der Nachfrage',
      seleccioneMotivo: 'Wählen Sie',
      fecha: 'Datum',
      escribaMasInformacionParaPadre: 'Weitere Informationen eingeben',
      escribaMasInformacion: 'Weitere Informationen eingeben',
      seleccionaUnFamiliar:
        'Wählen Sie ein Familienmitglied aus oder geben Sie die Daten des neuen Familienmitglieds ein',
      escribaElTituloDelEvento: 'Geben Sie den Titel der Veranstaltung ein',
      escribaLaDecripcionDelEvento:
        'Schreiben Sie die Beschreibung des Ereignisses',
      otroMotivoObservacion: 'Ein anderer Grund',
      describeOtroMotivoObservacion: 'Beschreibe den Grund',
      motivoTicket: 'Art des Problems',
      escribaProblemaTicket: 'Könnten Sie bitte mehr Details angeben?',
      subirArchivos: 'Dateien anhängen',
      dropzoneTextFiles:
        'Datei hierher ziehen und ablegen oder klicken. (Maximale Größe: 10 MB)',
      creandoTicket: 'Ticket wird gesendet, bitte warten',
      descripcionTicket: 'Beschreibung des Problems',
      ubicacionTicket: 'Geben Sie den Ort an',
      archivoSeleccionado: 'Archivo seleccionado' // TODO: traduccion 
    },
    opcion: {
      MOTIVO_SALUD: 'KRANKHEIT',
      MOTIVO_VIAJE: 'GESUNDHEIT REISEN',
      MOTIVO_VIAJE_OTRO: 'VERSCHIEDENE AUSFLÜGE',
      MOTIVO_OTRO: 'VERSCHIEDENES',
      MOTIVO_TORNEO: 'SPORTTURNIERE',
      MOTIVO_AUSENCIA: 'NOTIFIED ABSENCE',
      MOTIVO_CITA_MEDICA: 'ARZTTERMINE / MEDIZINISCHE UNTERSUCHUNGEN',
      MOTIVO_TRAMITES: 'FORMALE ANLÄSSE',
      SALUD: 'KRANKHEIT',
      VIAJE: 'GESUNDHEIT REISEN',
      VIAJE_OTRO: 'VERSCHIEDENE AUSFLÜGE',
      OTRO: 'VERSCHIEDENES',
      TORNEO: 'SPORTTURNIERE',
      AUSENCIA: 'Gemeldete abwesenheit',
      CITA_MEDICA: 'ARZTTERMINE / MEDIZINISCHE UNTERSUCHUNGEN',
      TRAMITES: 'FORMALE ANLÄSSE',
    },
    mensaje: {
      contraseniaIncorrecta: 'Das Passwort ist inkorrekt',
      noExisteUsuario: 'Benutzer existiert nicht',
      correoInvalido: 'Es ist keine gültige E-Mail',
      errorDesconocido: 'Unbekannter Fehler',
      correcto: 'Richtig!',
      error: 'Fehler!',
      advertencia: 'Warnung!',
      confirmar: 'Bestätigen Sie',
    },
    reportes: 'Berichte',
    reporte: {
      permisosPorEstudiante: 'Freistellungen',
      asistenciasPorMateria: 'Assistenzbericht',
      libroClasesPorSesion: 'Klassenbuch',
      libroClasesAcumulado: 'Klassenbuch nicht gefüllt (kumuliert)',
      trimestral: 'Anwesenheit und Anmerkungen',
      datosGenerales: 'Allgemeine Daten',
      kardexDetalle: 'Detail',
      asistencia: 'Teilnahme',
      anotaciones: 'Anmerkungen',
      tareas: 'Hausarbeiten',
      correoEstudiante: 'Studentische Post',
      curso: 'Kurs',
      padre: 'Vater',
      correoPadre: 'Post des Vaters',
      historial: 'Register',
      buscarEstudiante: 'Schüler finden',
      cargando: 'Laden',
      sinOpciones: 'keine Optionen',
      verAsistencias: 'Siehe Assists',
      verComoJefeCurso: 'Als Kursleiter ansehen',
      estudiante: 'Schüler',
      familiar1: 'Verwandten Nr. 1',
      familiar2: 'Verwandten Nr. 2',
      correo: 'E-Mail Adresse',
      celular: 'Contact',
      direccion: 'Adresse',
      kinderAsistencia: 'Anwesenheit',
      permisos: 'Genehmigungen',
      libroDeCursoNoLlenado: 'Nicht ausgefülltes Gruppenbuch',
      kinderGraficoAreasDesarrollo: 'Grafik Entwicklungsbereiche',
      kinderGraficoAreas: 'Kompetenz Grafik',
      alergias: 'Alergien',
      accidentes: 'Unfälle',
    },
    date: {
      long: 'dddd, D MMMM YYYY',
    },
    calendario: {
      siguiente: 'Vor',
      anterior: 'Zurück',
      hoy: 'Heute',
      hoyEs: 'Heute ist',
      mes: 'Monat',
      semana: 'Woche',
      dia: 'Tag',
      agenda: 'Schüleragenda',
      todoElDia: 'Ganztägig',
      feriado: 'Urlaub',
      eventoInterno: 'Internes Ereignis',
      comienza: 'Seit',
      termina: 'Bis',
      noHayEventosEnEsteRango:
        'Es gibt keine Veranstaltungen in diesem Zeitraum',
      evento: 'Fall',
      fecha: 'Datum',
      lunes: 'Montag',
      martes: 'Dienstag',
      miercoles: 'Mittwoch',
      jueves: 'Donnerstag',
      viernes: 'Freitag',
      sabado: 'Samstag',
      domingo: 'Sonntag',
      enero: 'Januar',
      febrero: 'Februar',
      marzo: 'März',
      abril: 'April',
      mayo: 'Mai',
      junio: 'Juni',
      julio: 'Juli',
      agosto: 'August',
      septiembre: 'September',
      octubre: 'Oktober',
      noviembre: 'November',
      diciembre: 'Dezember',
    },
    tabla: {
      refrescar: 'Neu laden',
      filasPorPagina: 'Zeilen pro Seite',
      desde: 'Seit',
      hasta: 'Bis',
      sinRegistrosReporte: 'Keine Berichtsdaten',
      buscar: 'Suchen',
      buscarPor: 'Zu suchen',
      de: 'von',
      masDe: 'mehr von',
      generarExcel: 'Excel herunterladen',
      generarExcelPermisos: 'Tagesbericht herunterladen',
      nro: 'Nr.',
      nombre: 'Vorname',
      primerApellido: 'Nachname',
      segundoApellido: '2. Nachname',
      nivel: 'Stufe',
      grado: 'Jahrgang',
      curso: 'Kurs',
      permisosSolicitados: 'Beantragte Freistellungen',
      permisosAprobados: 'Vergebene Freistellungen',
      permisosRechazados: 'Abgelehnte Freistellungen',
      primaria: 'Grundschule',
      secundaria: 'Sekundarstufe',
      kinder: 'Kindergarten',
      asistencia: 'Anwesenheit',
      atrasos: 'Verspätungen',
      faltasConLicencia: 'Entschuldigtes Fehlen',
      faltasSinLicencia: 'Unentschuldigtes Fehlen',
      minutosAcumulados: 'Angesammelte Verspätungsminuten',
      anotaciones: 'Anmerkungen',
      nombreProfesor: 'Vorname der Lehrkraft',
      primerApellidoProfesor: 'Nachname der Lehrkraft',
      segundoApellidoProfesor: '2. Nachname der Lehrkraft',
      siglaProfesor: 'Namenskürzel der Lehrkraft',
      materia: 'Fach',
      fecha: 'Datum',
      avance: 'Stundeninhalt',
      salud: 'Gesundheit',
      viaje: 'Reise',
      otro: 'Anderes',
      recibido: 'Empfangt',
      aprobado: 'Genehmigt',
      rechazado: 'Abgelehnt',
      observado: 'Beobachtet',
      acciones: 'Aktionen',
      fechaPublicacion: 'Veröffentlichungsdatum',
      fechaActualizacion: 'Letzte Aktualisierung',
      estado: 'Status:',
      nombreCompleto: 'Vorname und Nachname',
      paralelo: 'Parallel',
      turno: 'Schicht',
      todos: 'Alle',
      asesor: 'Kursleiter',
      fechaInicio: 'Datum des Beginns',
      fechaFin: 'Datum des Endes',
      gestion: 'Jahr',
      asistio: 'Anwesend',
      faltaSinLicencia: 'Unentschuldigtes Fehlen',
      faltaConLicencia: 'Genehmigten Abwesenheiten',
      atrasado: 'Verspätung',
      llenarLibroClase: 'Jetzt das Klassenbuch ausfüllen',
      tipoProblema: 'Art des Problems',
      contenido: 'Inhalt',
    },
    crearEstudiante: 'Student erstellen',
    sinRegistroEstudiantes: 'Keine Schülerdaten',
    sinRegistroCursos: 'Keine Kursinformationen',
    crearCurso: 'Kurs erstellen',
    crearMateria: 'Materie erstellen',
    leccionDelDia: 'Die heutige Lektion',
    anotacionesSinTarea: 'Notizen ohne Hausaufgaben',
    escribeDesarrolloClase:
      'Schreiben Sie die Entwicklung der heutigen Klasse auf',
    enEstaClaseNoSeDejoTarea:
      'In dieser Klasse gibt es keine Hausaufgaben für die Schüler/innen',
    seleccioneEstudiantesSinTarea:
      'Wählen Sie als Nächstes die Schüler aus, die ihre Aufgabe nicht abgegeben haben.',
    asistenciaPresente: 'Anwesend',
    asistenciaRetraso: 'Verspätung',
    asistenciaFaltaSinLicencia: 'Unentschuldigtes Fehlen',
    noPresentado: 'Hausaufgaben nicht abgegeben',
    aContinuacionSeleccione: 'Auswählen:',
    entrada: 'Eingabe',
    salida: 'Ausgabe',
    seleccioneLaCantidadMinutosRetraso:
      'Anzahl der Minuten der Verspätung auswählen',
    hora: 'Stunden',
    horaMinuto: 'Stunden-Minuten',
    totalMinutosEntrada: 'Gesamtminuteneingang: ',
    totalMinutosSalida: 'Gesamtminutenausgang:',
    horas: 'stunden',
    minutos: 'minuten',
    kinder: {
      volverMenu: 'Zurück',
      tema: 'Thema',
      circuloDeColores: 'Farbkreisaktivität',
      circulo: 'Círculo',
      deColores: 'de colores',
      asistencia: 'Anwesenheit',
      fecha: 'Datum',
      libroDeCurso: 'Klassenbuch',
      libroDeCursoNoCamel: 'Klassenbuch',
      libroDeCursoNoCamelB: 'Gruppenbuch',
      anadirActividad: 'Aktivität hinzufügen',
      sinActividades:
        'In diesem Bereich wird derzeit keine Aktivität erstellt.',
      crearNuevoTema: 'Neues Thema erstellen',
      crearModalNuevoTema: 'Thema erstellen',
      crearModalNuevoCirculo: 'Farbkreis erstellen',
      tituloTema: 'Titel des Themas',
      tituloCirculo: 'Titel der Farbkreises',
      introduceTituloTema: 'Den Titel des Themas eingeben',
      introduceTituloCirculo: 'Geben Sie den Titel des Farbkreises ein',
      temaFechaInicioFin: 'Beginn- und Enddatum',
      cancelar: 'Löschen',
      guardar: 'Speichern',
      actividadesMananaTitle: 'Aktivitäten der Morgenrunde',
      actividadesOtrosTitle: 'Andere Aktivitäten',
      eventosDelDia: 'Ereignisse des Tages',
      guardarLibroDeCurso: 'Klassenbuch speichern',
      selecciona: 'Wählen',
      nombreActividad: 'Beschreibung der Aktivität',
      actividades: 'Aktivitäten',
      competencias: 'Kompetenzen',
      colEstudiante: 'Schüler',
      colAsistencia: 'Anwesenheit',
      colRetraso: 'Verspatüng',
      primariaSecundaria: 'GRUNDSCHULE-SEKUNDARSTUFE',
      kinder: 'Kindergarten',
      competenciasKinder: 'Kindergarten Kompetenzen',
      formularioRegistroPermiso: 'Anmeldeformular für Genehmigungen',
      formularioRegistroAccidentes: 'Unfallmeldeformular',
      datosEstudiante: 'Daten der Studenten',
      datosPermiso: 'Daten der Genehmigung',
      comentario: 'Anmerkung',
      menuDelLibroDeCurso: 'Menü des Klassenbuchs',
      registrarPermisos: 'Genehmigungsregistrierung',
      accidentes: 'Unfälle',
      estudiantesPresentes: 'Anwesende Schüler/innen',
      estudiantesFaltaSinLicencia:
        'Schüler/innen mit unerlaubten Abwesenheiten',
      estudiantesFaltaConLicencia:
        'Schüler/innen mit genehmigten Abwesenheiten',
      seleccioneEstadoAsistencia:
        'Wählen Sie dann den Anwesenheitsstatus des Schülers aus',
      asistenciaRegistrada: 'Registrierte anwesenheit',
      validacionNombre:
        'Alle Aktivitäten sollten einen Namen haben, überprüfen und erneut versuchen.',
      validacionMinimoCompetencias:
        'Die Mindestanzahl der erforderlichen Kompetenzen (1) wurde noch nicht erreicht. Bitte füllen Sie die Felder aus und versuchen Sie es erneut.',
      validacionOtrasActividades:
        'Der Abschnitt "SONSTIGE TÄTIGKEITEN UND KOMPETENZEN" ist leer. Bitte geben Sie eine Tätigkeit und mindestens eine Kompetenz an, um fortzufahren.',
      validacionActividadesManana:
        'Der Abschnitt "AKTIVITÄTEN UND KOMPETENZEN FÜR DEN MORGENKREIS" ist leer, fügen Sie eine Aktivität und mindestens eine Kompetenz ein, um fortzufahren.',
      contactoEmergencia: 'Notfallkontakt',
      parentesco: 'Verwandschaftsbeziehung',
      contacto: 'Kontakt',
      curso: 'Klasse',
      area: 'Bereich',
      seleccionarAreaBuscar:
        'Wählen Sie einen Bereich und drücken Sie auf Suchen',
      gestion: 'Jahr',
      comoFueAtendido: 'Ergriffene Maßnahmen',
    },
    agregarAnotacion: 'Anmerkung hinzufügen',
    anotacion: 'Anmerkung',
    descargarPDF: 'Download PDF',
    datosDelFamiliar: 'Angaben zu Verwandten Nr.',
    fechaAltaMedicaSolicitada: 'Datum des ärztlichen Attests',
    solicitudAltaMedicaAprobada: 'Antrag auf medizinische Entlastung genehmigt',
    solicitudAltaMedicaRecibida: 'ärztliches Attest erhalten',
    solicitudAltaMedicaObservada:
      'Beobachtetes medizinisches Entlassungsgesuch',
    solicitudAltaMedicaRechazada:
      'Antrag auf medizinische Entlastung abgelehnt',
    formularioSolicitudPermiso: 'Antragsformular',
    informacionEnviadaCorrectamente: 'Korrekt übermittelte Informationen',
    edicionPermisoExitoso: 'Aktualisierter Genehmigungsantrag',
    creacionPermisoExitoso: 'Erfolgreiche Erstellung der Genehmigung',
    accidenteRegistrado: 'Korrekt registrierter Unfall',
    solicitudPermiso: 'Genehmigungsanträge',
    confirm: {
      deseaAprobarPermiso: 'Wollen Sie die Genehmigung genehmigen?',
      deseaRechazarPermiso: 'Wollen Sie die Erlaubnis verweigern?',
      deseaSolicitarMasInformacion:
        'Möchten Sie weitere Informationen anfordern?',
      deseaEliminarPermiso: 'Möchten Sie die Genehmigung annullieren?',
      deseaAprobarAltaMedica:
        'Möchten Sie die medizinische Entlassung genehmigen?',
      deseaRechazarAltaMedica:
        'Möchten Sie die medizinische Entlassung verweigern?',
      deseaContinuarYEliminarTarea:
        'Es wurde eine Aufgabe gefunden und wird gelöscht. Möchten Sie fortfahren?',
    },
    observacion: 'Anmerkung',
    notificaciones: {
      enviadas: 'Gesendete Benachrichtigungen',
    },
    notificacion: 'Benachrichtigung',
    enviarNotificacion: 'Benachrichtigung senden',
    help: {
      kinderFAQ: 'HÄUFIG GESTELLTE FRAGEN – KINDERGARTEN',
      q1: 'Wie erhalte ich Zugang zur Plattform?',
      a1: 'Über jedes Gerät mit einer Internetverbindung (Computer oder Tablet) können Sie mit Ihrem bevorzugten Browser auf den Link &nbsp; <a href="https://ds-lapaz.io" target="_blank">https://ds-lapaz.io</a> &nbsp; zugreifen.',
      q2: 'Wie kann ich die Sprache in der APP ändern?',
      a2: 'Die Standardsprache der Plattform ist Spanisch. Man kann jedoch zwischen Spanisch und Deutsch umschalten, indem er auf die Schaltfläche IDIOMA (ES) im oberen Menüband klickt. Der Text wird dann in IDIOMA (DE) umgewandelt, ebenso wie der gesamte Text auf der Plattform.',
      q3: 'Ich habe mich im Klassenbuch angemeldet, aber die Schaltfläche "Aktivität hinzufügen" ist blockiert. Warum ist das so?',
      a3: 'Die Plattform ist so entwickelt, dass sie einem bestimmten Verfahren folgt. Um Aktivitäten hinzuzufügen, muss zunächst ein Thema zu einem bestimmten Datum erstellt werden. Sobald dies geschehen ist, ändert die Schaltfläche ihre Farbe und wird aktiviert (die Erstellung des Farbkreises ist freiwillig).',
      q4: 'Kann ich das Klassenbuch für mehrere Wochen einplanen?',
      a4: 'Ja, die Plattform ermöglicht es Ihnen, im Voraus zu planen und die Themen, Farbkreise, Aktivitäten und Kompetenzen für spätere Termine als den aktuellen zu erstellen.',
      q5: 'Kann ich verschiedene Datumsbereiche für das Thema und die Farbkreise festlegen?',
      a5: 'Ja, es handelt sich um getrennte Felder, so dass jedes eine andere Dauer haben kann.',
      q6: 'Sollte ich mich jeden Tag einloggen, um die Anwesenheitsliste zu speichern?',
      a6: 'Es ist ratsam, die tägliche Anwesenheit zu erfassen, um zu bestätigen, dass alle Kinder in den Stunden anwesend sind; wenn jedoch am aktuellen Datum keine Änderungen erfasst werden, wird die Plattform standardmäßig erfassen, dass alle Kinder anwesend waren.',
      q7: 'Welche Berichte kann ich als Lehrer/in einsehen?',
      a7: 'Anwesenheit: hier können Sie die Anwesenheitsliste jedes Kindes, das innerhalb eines bestimmten Zeitraums in Ihrer Klasse eingeschrieben ist, die Anzahl der kumulierten Anwesenheiten, Abwesenheiten ohne Urlaub, Abwesenheiten mit Urlaub, Verspätungen und die kumulierten Minuten sehen.<br/>Kompetenzen: darin können Sie grafisch darstellen, wie Sie im Unterricht entsprechend den für jede Aktivität festgelegten Entwicklungsbereichen und Kompetenzen abgeschnitten haben. So können Sie feststellen, welche Bereiche und Kompetenzen am stärksten und welche am wenigsten stark entwickelt sind.',
      q8: 'Wie können beide Lehrkräfte auf die Informationen für dieselbe Klasse auf der Plattform zugreifen?',
      a8: 'Jeder Klasse ist so konzipiert, dass zwei Erzieher/innen dafür verantwortlich sind. Jeder hat mit seinen eigenen Zugangsdaten (d.h. E-Mail und Passwort) individuellen Zugang zum Klassenbuch, zu den Berichten und zum Kardex.',
      q9: '¿Dónde puedo ver el listado de asistencia para hacer el registro?',
      a9: 'En el primer apartado denominado “Libro de curso”, es el tercer botón a la derecha del calendario semanal.',
      q10: 'Si como profesor me llegó un correo de los padres indicando que el estudiante faltará, ¿puedo registrar una falta con licencia en la asistencia del día de hoy?',
      a10: 'El profesor no puede cambiar el estado de un estudiante a “falta con licencia”. El procedimiento formal de gestión de licencias, parciales y totales, es que el papá envía una solicitud a través de la aplicación y es el monitor del colegio quien se encarga de aprobarlos, rechazarlos u observarlos según ciertos criterios. Lo ideal es que se exhorte a los padres de familia a hacer uso de la aplicación para solicitar el permiso.',
      q11: '¿Qué pasa si ya le registré a un niño o niña una falta sin licencia y se les aprobó el permiso a los padres días después?. ¿Se me informará para cambiar el estado de asistencia?',
      a11: 'Una vez que la solicitud de permiso sea aprobada por el colegio, la información se consolidará por sobre las faltas sin licencia registradas en tales días. Es entonces que el estado del estudiante cambiará automáticamente a “Tiene licencia” y esto se reflejará en todos los reportes oficiales.',
      q12: '¿Cómo puedo ver los reportes de mi curso?',
      a12: 'Primeramente, se debe hacer clic en el botón “Reportes”. En la parte central superior se verá el texto “Primaria – Secundaria”, esta es una lista de selección, si pulsamos sobre la misma tendremos una segunda opción denominada “Kínder”. Elegimos “Kínder” y los reportes en pantalla que se mostrarán, serán los del nivel respectivo.',
      q13: 'En el Gráfico de competencias me indica que “No hay datos”. ¿Cómo soluciono esto?',
      a13: 'Se debe proceder a activar el filtro, primer botón dentro de la barra de opciones con tres líneas horizontales. Se desplegará una segunda línea con dos campos: Curso y Área.<br/><br/>Pulsamos sobre los espacios respectivos y primero seleccionamos nuestro curso, posteriormente   en   el   campo   “área”,   elegimos   el   área   de   desarrollo   cuyas competencias queramos reflejar en función a la frecuencia de aplicación y el gráfico aparecerá en pantalla.',
    },
    categoriaAnotacion: {
      otro: 'Verschiedenes',
      aplicacion: 'Fleiß',
      sinTarea: 'Keine Hausaufgaben',
      conducta: 'Verhalten',
      orden: 'Ordnung',
      atencion: 'Aufmerksamkeit',
    },
    anotacionesGuardadasConExito:
      'Die Anmerkungen wurden erfolgreich gespeichert',
    asistenciasGuardadasConExito: 'Assist-Liste erfolgreich eingetragen',
    tareasGuardadasConExito:
      'Die Aufgaben werden gespeichert. Bitte warten Sie',
    // #region TICKETS
    crearTicket: 'Ticket erstellen',
    infoCrearTicket:
      'Wenn Sie ein Problem haben, senden Sie bitte ein Ticket, und wir werden Ihnen so schnell wie möglich antworten.',
    enviarTicket: 'Ticket senden',
    ubicacion: 'Ort',
    sinDatosTickets: ' Es gibt keine Ticket-Daten',
    // #endregion
    // #region CERTIFICADOS
    certificado: 'Zertifikat',
    certificadoNuevo: 'Neues Zertifikat erstellen',
    editarCertificado: 'Zertifikat bearbeiten',
    certificadoExterno: 'Externes Zertifikat erstellen (PDF)',
    subirCertificado: 'Bestehendes Zertifikat hochladen',
    certificadoProfesor: 'Lehrerzertifikat', // TODO: 
    tipoCertificado: 'Zertifikatstyp',
    sinRegistrosCertificados: 'Es gibt keine Zertifikate',
    seleccioneTipoCertificado: 'Zertifikat...',
    datosDestinatario: 'Empfängerdaten',
    textoAyudaCertificadoExistenteDestinatario:
      'Suchen und auswählen, an wen das Zertifikat gesendet wird',
    esProfesor: 'Ist ein Lehrer',
    buscarProfesor: 'Lehrer suchen',
    buscarEstudiante: 'Student suchen',
    seleccionarCertificado: 'Zertifikatstyp auswählen',
    cargarListaAyuda:
      'Laden Sie eine Empfängerliste hoch, um das Zertifikat zu generieren',
    cargarLista: 'Empfängerliste hochladen',
    completarTextoCertificado:
      'Vervollständigen Sie den Text nach dem generischen Satz:',
    textAyudaCertificado:
      'Es ist nicht notwendig, den Satz erneut zu schreiben (bereits im Zertifikat enthalten).',
    descargarCertificadoEjemplo:
      'Optional: Laden Sie die Vorlage herunter, um die Daten korrekt einzugeben, falls erforderlich',
    regresar: 'Zurück zum Bearbeiten',
    revisionConfirmacionCertificados:
      'Überprüfung und Bestätigung der Zertifikate',
    revisionConfirmacionCertificadosTitulo:
      'Überprüfen Sie die Zertifikatsliste und nehmen Sie bei Bedarf Änderungen vor, indem Sie zurück zum Bearbeiten gehen',
    numeroDeCertificados: 'Anzahl der Zertifikate: ',
    sinCertificadosRevision: 'Kein Zertifikat zum Generieren gefunden',
    generarCertificados: 'Zertifikate generieren',
    creandoCertificados: 'Zertifikate wurden erfolgreich gesendet.',
    agregarAdjunto: 'Anhang hinzufügen',
    textoAyudaAdjunto:
      'Der laden Sie die Datei von Ihrem Gerät hoch, um das Zertifikat anzuhängen',
    previsualizarCertificado: 'Zertifikat anzeigen',
    fechaCertificado: 'Wählen Sie das Datum des Zertifikats aus',
    debeAdjuntarArchivoOIngresarURL:
      'Sie müssen eine Datei anhängen oder eine URL eingeben',
    debeSeleccionarUsuario: 'Sie müssen einen Benutzer auswählen',
    // TODO: traducciones
    datosEspaniolCertificado: 'Inserta los datos comunes para generar los certificados en ESPAÑOL, por favor.',
    datosAlemanCertificado: 'Inserta los datos comunes para generar los certificados en ALEMÁN, por favor.',
    temaCertificado: 'Tema del certificado',
    tematicasFormacion: 'Temáticas de la formación',
    capacitadores: 'Lehrerfortbilder',
    participantes: 'Teilnehmender Lehrer'
    // #endregion
  },
};

export default translations;
