import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Switch,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {
  ArrowBack,
  Close,
  FileCopy,
  Link,
  NavigateNext,
  Save,
} from '@material-ui/icons';
import { Alert, Autocomplete } from '@material-ui/lab';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import {
  addCertificates,
  descargarCSVEjemplo,
  generarCertificadoTemporal,
  verificarCertificadoUsuarios,
} from 'store/actions/certificado';
import esLocale from 'date-fns/locale/es';
import React from 'react';
import { RootState, useStateSelector } from 'store/reducers';
import {
  ICertificateForm,
  IUSuariosVerificadosCertificado,
} from 'models/certificate_model';
import moment from 'moment';
import message from 'plugins/utils/message';

export interface ICertificateProfesorForm {
  tipoCertificado: string;
  fechaCertificado: string;
  archivo: any;
  fraseEs: string;
  temasEs: string;
  fraseDe: string;
  temasDe: string;
}

type FormCertificateProfesorProps = {
  loadingModal: boolean;
  isModalOpen: boolean;
  tiposCertificados: TipoCertificados[];
  handleClose: () => void;
  handleSave: () => void;
};

type SelectOption = {
  value: string | number;
  label: string;
  caption: string;
};

type TipoCertificados = {
  value: string;
  label: string;
  caption: string;
  frase: string;
  fraseInicialDe: string | null;
  cantidadCaracteres: number;
  cantidadCaracteresTematicas?: number | null;
};

const FormCertificateProfesor: React.FC<FormCertificateProfesorProps> = ({
  loadingModal,
  isModalOpen,
  tiposCertificados,
  handleClose,
  handleSave,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { locale } = useStateSelector((state: RootState) => state.i18n);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { userVerification } = useStateSelector(
    (state: RootState) => state.certificateState,
  );

  const [loading, setLoading] = useState(false);

  const [stepActual, setStepActual] = useState(1);
  const [idiomaCertificados, setIdiomaCertificados] = useState<'es' | 'de'>(
    'es',
  );

  const { control, setValue, watch, formState, handleSubmit } =
    useForm<ICertificateProfesorForm>({
      defaultValues: {
        archivo: null,
        fechaCertificado: '',
      },
      shouldUnregister: false,
      mode: 'all',
    });
  const [fileList, setFileList] = React.useState([]);

  const onNextStep = async () => {
    if (stepActual !== 1) return;
    const data = watch();

    setLoading(true);

    const result = await dispatch(
      verificarCertificadoUsuarios({
        tipoCertificado: data.tipoCertificado,
        frase: data.fraseEs,
        fechaCertificado: data.fechaCertificado,
        archivo: data.archivo,
        tipoCertificadoGenerar: 'PROFESORES',
      }),
    );
    setLoading(false);
    if (!result) {
      setStepActual(1);
      return;
    }
    setStepActual(2);
  };

  const handleFileChange = (files: any) => {
    if (files.length > 0) {
      setFileList(Array.from(files)); // Almacena los archivos seleccionados en el estado
    }
  };

  const handleDelete = () => {
    setFileList([]);
    setValue('archivo', null);
  };

  const downloadExampleReportExcel = async () => {
    setLoading(true);
    await dispatch(descargarCSVEjemplo('PROFESOR'));
    setLoading(false);
  };

  const onSubmit = async (data: ICertificateProfesorForm) => {
    const msj = `Se van a generar un total de ${
      userVerification.length
    } certificados en fecha ${moment().format(
      'DD/MM/YYYY',
    )} ¿Confirmar el envío?`;

    const msjAleman = `Es werden insgesamt ${
      userVerification.length
    } Zertifikate am ${moment()
      .locale('de')
      .format('DD/MM/YYYY')} generiert. Versand bestätigen?`;

    const send = await message.confirm(locale === 'es' ? msj : msjAleman, true);

    if (!send) return;
    setLoading(true);

    const dataCreate: ICertificateForm = {
      tipoCertificado: data.tipoCertificado,
      archivo: data.archivo,
      frase: !data.fraseDe
        ? data.fraseEs
        : `${data.fraseEs}${'|'}${data.fraseDe}`,
      fechaCertificado: data.fechaCertificado,
      temas: `${data.temasEs}${'|'}${data.temasDe}`,
      tipoCertificadoGenerar: 'PROFESORES',
    };

    const result = await dispatch(addCertificates(dataCreate));
    setLoading(false);

    if (!result) {
      return;
    }
    message.success(
      `${userVerification.length} ${I18n.t('creandoCertificados')}`,
    );
    handleSave();
  };

  const downloadCertificateTemporal = async (
    datos: IUSuariosVerificadosCertificado,
    nombre: string,
  ) => {
    setLoading(true);
    const idPlantilla = datos.idTipoCertificado
      ? datos.idTipoCertificado
      : watch().tipoCertificado;
    if (!idPlantilla) {
      setLoading(false);
      message.error('No se ha seleccionado una plantilla');
      return;
    }
    await dispatch(
      generarCertificadoTemporal(
        {
          ...datos,
          fecha: watch().fechaCertificado,
          frase:
            idiomaCertificados === 'es' ? watch().fraseEs : watch().fraseDe,
          idPlantilla,
          idioma: idiomaCertificados,
          genero: datos.genero,
          temas: `${watch().temasEs}${'|'}${watch().temasDe}`,
        },
        nombre,
      ),
    );
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const RenderForm = useCallback(() => {
    const esCertificadoProfesor =
      tiposCertificados.find((item) => item.value === watch().tipoCertificado)
        ?.label === 'Profesor';

    return (
      <Box mb={3}>
        <Alert variant="outlined" severity="info">
          <Box
            style={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'row',
              gap: 10,
            }}
            onClick={downloadExampleReportExcel}
          >
            <Typography>
              <Translate value="descargarCertificadoEjemplo" />
            </Typography>

            <svg
              width="17"
              height="18"
              viewBox="0 0 17 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.8611 9.14147C16.666 8.90748 16.295 8.7616 15.8915 8.7616H9.60854V0.734294C9.60854 0.328964 9.11193 0 8.50004 0C7.88815 0 7.39154 0.328964 7.39154 0.734294V8.7616L1.10858 8.7616C0.703606 8.7616 0.332628 8.90748 0.139011 9.14147C-0.0575638 9.37547 -0.0442619 9.66135 0.170048 9.88752L7.56151 17.6573C7.76548 17.8708 8.11872 18 8.50004 18C8.88136 18 9.2346 17.8708 9.43857 17.6573L16.83 9.88752C16.9424 9.76807 17 9.63198 17 9.49589C17 9.37449 16.9527 9.25211 16.8611 9.14147Z"
                fill="#323232"
              />
            </svg>
          </Box>
        </Alert>
        <Grid
          container
          spacing={2}
          style={{
            marginTop: 10,
          }}
        >
          <Grid item xs={12} md={6}>
            {/* <Typography
              gutterBottom
              variant="h6"
              style={{
                fontWeight: 'bold',
                marginBottom: 10,
              }}
            >
              <Translate value="seleccionarCertificado" />
            </Typography> */}
            {/* <Controller
              name="tipoCertificado"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <FormControl
                  variant="filled"
                  required
                  disabled={tiposCertificados.length === 0}
                  className={classes.formControl}
                >
                  <Autocomplete
                    options={tiposCertificados}
                    autoHighlight
                    getOptionLabel={(option: SelectOption) => option.label}
                    renderOption={(option: SelectOption, state) => (
                      <Box {...state}>
                        <Typography variant="body2">{option.label}</Typography>
                      </Box>
                    )}
                    value={
                      tiposCertificados.find(
                        (option) => option.value === field.value,
                      ) || null
                    }
                    onChange={(_event, newValue: SelectOption | null) => {
                      field.onChange(newValue ? newValue.value : '');
                    }}
                    disabled={tiposCertificados.length === 0}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={I18n.t('seleccioneTipoCertificado')}
                        variant="filled"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password',
                        }}
                      />
                    )}
                  />
                </FormControl>
              )}
              rules={{
                required: I18n.t('seleccioneTipoCertificado'),
              }}
            /> */}

            <Grid item xs={12} md={12}>
              <Typography
                gutterBottom
                variant="h6"
                style={{
                  fontWeight: 'bold',
                  marginBottom: 10,
                }}
              >
                {fileList.length > 0 ? (
                  <>
                    <Translate value="form.archivoSeleccionado" />:
                  </>
                ) : (
                  <Translate value="datosDestinatario" />
                )}
              </Typography>
              {fileList.length === 0 && (
                <Typography
                  gutterBottom
                  style={{
                    marginBottom: 10,
                  }}
                >
                  <Translate value="cargarListaAyuda" />
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} md={12}>
              {(fileList.length === 0 || !fileList) && (
                <Controller
                  name="archivo"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: 'Es necesario subir un archivo',
                    validate: {
                      checkFileType: (value) => {
                        const file = value[0];
                        const validTypes = [
                          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                          'application/vnd.ms-excel',
                        ];
                        return (
                          validTypes.includes(file?.type) ||
                          'Solo se permiten archivos Excel'
                        );
                      },
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <label className={classes.customFileUpload}>
                        <FileCopy />
                        <span>{I18n.t('cargarLista')}</span>
                        <input
                          type="file"
                          accept=".xlsx,.xls"
                          onChange={(e) => {
                            const xlsx = e.target.value.split('.').pop();
                            if (xlsx !== 'csv' && xlsx !== 'xlsx') {
                              return;
                            }

                            field.onChange(e.target.files);
                            handleFileChange(e.target.files);
                            setValue('archivo', e.target.files);
                          }}
                          onClick={(e) => {
                            e.currentTarget.value = '';
                          }}
                        />
                      </label>
                      {error && <p>{error.message}</p>}
                    </>
                  )}
                />
              )}

              {fileList.length > 0 && (
                <Box>
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    {fileList.map((file: any, index) => (
                      <Box
                        key={index}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          alignContent: 'center',
                          justifyContent: 'space-between',
                          paddingLeft: 10,
                          paddingRight: 10,
                          border: '1px solid #ddd',
                          borderRadius: 4,
                          width: '100%',
                          minWidth: '100%',
                        }}
                      >
                        <Typography>{file.name}</Typography>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          disabled={loadingModal || loading}
                          onClick={() => handleDelete()}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M18.9391 8.69713C19.1384 8.69713 19.3193 8.78413 19.4623 8.93113C19.5955 9.08813 19.6626 9.28313 19.6432 9.48913C19.6432 9.55712 19.1102 16.2971 18.8058 19.134C18.6152 20.875 17.4929 21.932 15.8094 21.961C14.5149 21.99 13.2496 22 12.0038 22C10.6811 22 9.38763 21.99 8.13206 21.961C6.50498 21.922 5.38168 20.846 5.20079 19.134C4.88763 16.2871 4.36439 9.55712 4.35467 9.48913C4.34494 9.28313 4.41108 9.08813 4.54529 8.93113C4.67756 8.78413 4.86818 8.69713 5.06852 8.69713H18.9391ZM14.0647 2C14.9488 2 15.7385 2.61699 15.967 3.49699L16.1304 4.22698C16.2627 4.82197 16.7781 5.24297 17.3714 5.24297H20.2871C20.6761 5.24297 21 5.56596 21 5.97696V6.35696C21 6.75795 20.6761 7.09095 20.2871 7.09095H3.71385C3.32386 7.09095 3 6.75795 3 6.35696V5.97696C3 5.56596 3.32386 5.24297 3.71385 5.24297H6.62957C7.22185 5.24297 7.7373 4.82197 7.87054 4.22798L8.02323 3.54598C8.26054 2.61699 9.0415 2 9.93527 2H14.0647Z"
                              fill={theme.palette.error.main}
                            />
                          </svg>
                        </IconButton>
                      </Box>
                    ))}
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography
              gutterBottom
              variant="h6"
              style={{
                fontWeight: 'bold',
                marginBottom: 10,
              }}
            >
              <Translate value="fechaCertificado" />
            </Typography>
            <FormControl className={classes.formControl} variant="filled">
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <Controller
                  name="fechaCertificado"
                  control={control}
                  defaultValue={null}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <KeyboardDatePicker
                      variant="inline"
                      id="fecha-certificado"
                      label=" "
                      autoOk
                      style={{
                        borderColor: 'black',
                        border: '1px',
                      }}
                      autoComplete="off"
                      format="dd/MM/yyyy"
                      value={value}
                      inputVariant="standard"
                      onChange={(date) => {
                        onChange(date);
                      }}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                  rules={{ required: 'La fecha es requerida' }}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Grid>

          <Grid
            container
            spacing={2}
            style={{ paddingInline: 10, marginTop: 10, width: '100%' }}
          >
            <Divider className={classes.divider} />

            {/* {watch().tipoCertificado &&
              (esCertificadoProfesor ? (
                <Grid item xs={12} md={12}>
                  <Typography
                    gutterBottom
                    variant="h6"
                    style={{
                      fontWeight: 'bold',
                      marginBottom: 10,
                    }}
                  >
                    <Translate value="completarTextoCertificado" />{' '}
                    {tiposCertificados.find(
                      (item) => item.value === watch().tipoCertificado,
                    )
                      ? tiposCertificados.find(
                          (item) => item.value === watch().tipoCertificado,
                        )?.frase + '...'
                      : ''}
                  </Typography>
                  <Typography
                    gutterBottom
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    <Translate value="textAyudaCertificado" />
                  </Typography>

                  <Controller
                    name="fraseEs"
                    control={control}
                    defaultValue=""
                    rules={{
                      required:
                        locale === 'es'
                          ? 'La frase es requerida'
                          : 'Der Satz ist erforderlich.',
                      maxLength: {
                        value:
                          tiposCertificados.find(
                            (item) => item.value === watch().tipoCertificado,
                          )?.cantidadCaracteres || 150,
                        message:
                          locale === 'es'
                            ? `La frase no puede superar los ${
                                tiposCertificados.find(
                                  (item) =>
                                    item.value === watch().tipoCertificado,
                                )?.cantidadCaracteres || 150
                              } caracteres`
                            : `Der Satz darf nicht mehr als ${
                                tiposCertificados.find(
                                  (item) =>
                                    item.value === watch().tipoCertificado,
                                )?.cantidadCaracteres || 150
                              } Zeichen enthalten.`,
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <TextField
                          {...field}
                          fullWidth
                          variant="filled"
                          required
                        />
                        {!!error && (
                          <FormHelperText error>
                            {error?.message}
                          </FormHelperText>
                        )}
                      </>
                    )}
                  />
                </Grid>
              ) : (
                
              ))} */}
            <Grid container spacing={2} style={{ marginTop: 10 }}>
              <Grid item xs={12} md={6}>
                <Box
                  style={{
                    border: '1px solid #ddd',
                    borderRadius: 10,
                    padding: 15,
                    gap: 10,
                  }}
                >
                  <Typography variant="body1">
                    <Translate value="datosEspaniolCertificado" />
                  </Typography>

                  <Typography
                    variant="body2"
                    style={{
                      marginTop: 10,
                      fontWeight: 'bold',
                      fontSize: 18,
                    }}
                  >
                    <Translate value="temaCertificado" />:
                  </Typography>

                  <Typography
                    variant="body1"
                    style={{
                      marginBottom: 10,
                    }}
                  >
                    <Translate value="completarTextoCertificado" /> Formación
                    Interna de Profesores sobre el tema
                  </Typography>

                  <Controller
                    name="fraseEs"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: esCertificadoProfesor
                        ? locale === 'es'
                          ? 'La frase es requerida'
                          : 'Der Satz ist erforderlich.'
                        : false,
                      maxLength: {
                        value:
                          tiposCertificados.find(
                            (item) => item.value === watch().tipoCertificado,
                          )?.cantidadCaracteres || 125,
                        message:
                          locale === 'es'
                            ? `La frase no puede superar los ${
                                tiposCertificados.find(
                                  (item) =>
                                    item.value === watch().tipoCertificado,
                                )?.cantidadCaracteres || 125
                              } caracteres`
                            : `Der Satz darf nicht mehr als ${
                                tiposCertificados.find(
                                  (item) =>
                                    item.value === watch().tipoCertificado,
                                )?.cantidadCaracteres || 125
                              } Zeichen enthalten.`,
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <TextField
                          {...field}
                          fullWidth
                          variant="filled"
                          required
                        />
                        {!!error && (
                          <FormHelperText error>
                            {error?.message}
                          </FormHelperText>
                        )}
                      </>
                    )}
                  />

                  <Typography
                    variant="body1"
                    style={{
                      marginTop: 10,
                      marginBottom: 10,
                      fontWeight: 'bold',
                      fontSize: 18,
                    }}
                  >
                    <Translate value="tematicasFormacion" />:
                  </Typography>

                  <Controller
                    name="temasEs"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: !esCertificadoProfesor
                        ? locale === 'es'
                          ? 'La temática es requerida'
                          : 'Das Thema ist erforderlich.'
                        : false,
                      maxLength: {
                        value:
                          tiposCertificados.find(
                            (item) => item.value === watch().tipoCertificado,
                          )?.cantidadCaracteresTematicas || 300,
                        message:
                          locale === 'es'
                            ? `Los temas no puede superar los ${
                                tiposCertificados.find(
                                  (item) =>
                                    item.value === watch().tipoCertificado,
                                )?.cantidadCaracteresTematicas || 300
                              } caracteres`
                            : `Der Satz darf nicht mehr als ${
                                tiposCertificados.find(
                                  (item) =>
                                    item.value === watch().tipoCertificado,
                                )?.cantidadCaracteresTematicas || 300
                              } Zeichen enthalten.`,
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <TextField
                          {...field}
                          fullWidth
                          variant="filled"
                          required
                        />
                        {!!error && (
                          <FormHelperText error>
                            {error?.message}
                          </FormHelperText>
                        )}
                      </>
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  style={{
                    border: '1px solid #ddd',
                    borderRadius: 10,
                    padding: 15,
                  }}
                >
                  <Typography variant="body1">
                    <Translate value="datosAlemanCertificado" />
                  </Typography>

                  <Typography
                    variant="body2"
                    style={{
                      marginTop: 10,
                      fontWeight: 'bold',
                      fontSize: 18,
                    }}
                  >
                    <Translate value="temaCertificado" />:
                  </Typography>

                  <Typography
                    variant="body1"
                    style={{
                      marginBottom: 10,
                    }}
                  >
                    <Translate value="completarTextoCertificado" /> der
                    schulinternen Lehrerfortbildung zum Thema
                  </Typography>

                  <Controller
                    name="fraseDe"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: !esCertificadoProfesor
                        ? locale === 'es'
                          ? 'La frase es requerida.'
                          : 'Der Satz ist erforderlich.'
                        : false,
                      maxLength: {
                        value:
                          tiposCertificados.find(
                            (item) => item.value === watch().tipoCertificado,
                          )?.cantidadCaracteres || 125,
                        message:
                          locale === 'es'
                            ? `La frase no puede superar los ${
                                tiposCertificados.find(
                                  (item) =>
                                    item.value === watch().tipoCertificado,
                                )?.cantidadCaracteres || 125
                              } caracteres`
                            : `Der Satz darf nicht mehr als ${
                                tiposCertificados.find(
                                  (item) =>
                                    item.value === watch().tipoCertificado,
                                )?.cantidadCaracteres || 125
                              } Zeichen enthalten.`,
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <TextField
                          {...field}
                          fullWidth
                          variant="filled"
                          required
                        />
                        {!!error && (
                          <FormHelperText error>
                            {error?.message}
                          </FormHelperText>
                        )}
                      </>
                    )}
                  />

                  <Typography
                    variant="body1"
                    style={{
                      marginTop: 10,
                      marginBottom: 10,
                      fontWeight: 'bold',
                      fontSize: 18,
                    }}
                  >
                    <Translate value="tematicasFormacion" />:
                  </Typography>

                  <Controller
                    name="temasDe"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: !esCertificadoProfesor
                        ? locale === 'es'
                          ? 'La temática es requerida'
                          : 'Das Thema ist erforderlich.'
                        : false,
                      maxLength: {
                        value:
                          tiposCertificados.find(
                            (item) => item.value === watch().tipoCertificado,
                          )?.cantidadCaracteresTematicas || 300,
                        message:
                          locale === 'es'
                            ? `La frase no puede superar los ${
                                tiposCertificados.find(
                                  (item) =>
                                    item.value === watch().tipoCertificado,
                                )?.cantidadCaracteresTematicas || 300
                              } caracteres`
                            : `Der Satz darf nicht mehr als ${
                                tiposCertificados.find(
                                  (item) =>
                                    item.value === watch().tipoCertificado,
                                )?.cantidadCaracteresTematicas || 300
                              } Zeichen enthalten.`,
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <TextField
                          {...field}
                          fullWidth
                          variant="filled"
                          required
                        />
                        {!!error && (
                          <FormHelperText error>
                            {error?.message}
                          </FormHelperText>
                        )}
                      </>
                    )}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList]);

  const RenderRevision = useCallback(() => {
    const esCertificadoProfesor =
      tiposCertificados.find((item) => item.value === watch().tipoCertificado)
        ?.label === 'Profesor';

    return (
      <Box>
        <Box>
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            <Translate value="revisionConfirmacionCertificados" />
          </Typography>

          <Typography variant="body1">
            <Translate value="revisionConfirmacionCertificadosTitulo" />
          </Typography>
        </Box>
        <Grid
          container
          style={{
            marginTop: 10,
          }}
        >
          <Grid container>
            <Grid item xs={12} md={8}>
              <Typography variant="body1" gutterBottom>
                <Translate value="numeroDeCertificados" />
                {watch().tipoCertificado ? (
                  userVerification.length
                ) : (
                  <>
                    {`${
                      userVerification.filter(
                        (item) =>
                          item.tipoCertificado === 'PROFESOR_CAPACITADOR',
                      ).length
                    }`}{' '}
                    <Translate value="capacitadores" />{' '}
                    {`y ${
                      userVerification.filter(
                        (item) =>
                          item.tipoCertificado === 'PROFESOR_CAPACITADOR',
                      ).length
                    }`}{' '}
                    <Translate value="participantes" />
                  </>
                )}
              </Typography>
            </Grid>
            {!esCertificadoProfesor && (
              <Grid item xs={12} md={4}>
                <FormControl
                  variant="filled"
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}
                >
                  <Typography variant="body1">
                    {locale === 'es' ? 'IDIOMA' : 'IDIOM'} (ES)
                  </Typography>
                  <Switch
                    checked={idiomaCertificados === 'es'}
                    onChange={() => {
                      setIdiomaCertificados((prev) =>
                        prev === 'es' ? 'de' : 'es',
                      );
                    }}
                  />
                </FormControl>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            {userVerification.length === 0 || !userVerification ? (
              <Box
                style={{
                  marginTop: '10px',
                  marginBottom: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography>
                  <Translate value="sinCertificadosRevision" />
                </Typography>
              </Box>
            ) : (
              <List style={{ maxHeight: '400px', overflowY: 'auto' }}>
                {userVerification.map((item, index) => (
                  <ListItem
                    key={index}
                    style={{
                      border: '1px solid #FFEB3B',
                      borderRadius: '5px',
                      marginBottom: '8px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    disabled={loading || loadingModal}
                  >
                    <ListItemIcon
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        if (loading || loadingModal) return;
                        downloadCertificateTemporal(item, item.nombreCompleto);
                      }}
                    >
                      <Link style={{ fontSize: '24px' }} />{' '}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.nombreCompleto}
                      primaryTypographyProps={{
                        style: {
                          fontWeight: 500,
                        },
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            )}
          </Grid>
        </Grid>
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userVerification, idiomaCertificados, loading]);

  return (
    <Dialog
      onClose={handleClose}
      open={isModalOpen}
      maxWidth={'lg'}
      scroll="paper"
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle id="scroll-dialog-title">
        <Grid container justify="space-between">
          {I18n.t('certificadoNuevo')}
          <IconButton
            disabled={loadingModal || loading}
            size="small"
            onClick={handleClose}
          >
            <Close />
          </IconButton>
        </Grid>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 10,
              marginBottom: 10,
            }}
          >
            <div
              style={{
                width: '20px',
                height: '20px',
                borderRadius: 50,
                color: '#FFFFFF',
                backgroundColor:
                  stepActual === 1
                    ? theme.palette.primary.light
                    : theme.palette.grey[400],
              }}
            ></div>
            <div
              style={{
                width: '20px',
                height: '20px',
                borderRadius: 50,
                backgroundColor:
                  stepActual === 2
                    ? theme.palette.primary.light
                    : theme.palette.grey[400],
              }}
            ></div>
          </Box>
          {stepActual === 1 && RenderForm()}
          {stepActual === 2 && RenderRevision()}
        </DialogContent>

        {(loading || loadingModal) && (
          <Box position="absolute" left={0} right={0}>
            <LinearProgress color="primary" />
          </Box>
        )}

        <DialogActions
          style={{
            margin: 10,
          }}
        >
          {stepActual === 2 && (
            <Button
              onClick={() => {
                setStepActual(1);
              }}
              disabled={loadingModal || loading}
              className={classes.btnBack}
              fullWidth
            >
              {isMobile ? <ArrowBack /> : <Translate value="regresar" />}
            </Button>
          )}
          <Button
            disabled={loadingModal || loading}
            onClick={handleClose}
            className={classes.btnError}
            fullWidth
          >
            {isMobile ? <Close /> : <Translate value="form.cancelar" />}
          </Button>
          {stepActual === 1 && (
            <Button
              disabled={!formState.isValid || loading || loadingModal}
              onClick={onNextStep}
              className={classes.btnNext}
              fullWidth
            >
              {isMobile ? (
                <NavigateNext />
              ) : (
                <Translate value="calendario.siguiente" />
              )}
            </Button>
          )}
          {stepActual === 2 && (
            <Button
              disabled={loadingModal || loading}
              type="submit"
              className={classes.btnSave}
              fullWidth
            >
              {isMobile ? <Save /> : <Translate value="generarCertificados" />}
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogPaper: {
      borderRadius: '16px',
      '&::-webkit-scrollbar': {
        width: '5px',
      },
    },
    btnNext: {
      maxWidth: '160px',
      backgroundColor: theme.palette.success.main,
      '&:hover': {
        backgroundColor: theme.palette.success.light,
      },
    },
    btnSave: {
      maxWidth: '250px',
      backgroundColor: theme.palette.success.main,
      '&:hover': {
        backgroundColor: theme.palette.success.light,
      },
    },
    btnBack: {
      maxWidth: '250px',
      backgroundColor: theme.palette.grey.A200,
      '&:hover': {
        backgroundColor: theme.palette.grey[200],
      },
    },
    btnError: {
      maxWidth: '160px',
      backgroundColor: theme.palette.error.main,
      '&:hover': {
        backgroundColor: theme.palette.error.light,
      },
    },
    formControl: {
      width: '100%',
    },
    customFileUpload: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      maxWidth: '300px',
      fontSize: '16px',
      fontWeight: 500,
      '& input[type="file"]': {
        display: 'none', // Esconde el input de archivo real
      },
      '& span': {
        marginLeft: '8px',
        textDecoration: 'underline',
        color: '#555',
      },
    },
    divider: {
      background: theme.palette.secondary.main,
      width: '100%',
      height: '1px',
    },
  }),
);

export default FormCertificateProfesor;
